<template>
   <div class="outer">
   <v-container fluid>
      <!--Title & Description -->
      <v-row class="pt-12">
         <v-col cols="12" class="pa-0 p-relative d-flex flex-column align-center justify-center">
            <!-- <div class="pl-5 ma-5">
               <v-img :src="assets.createSchedule.editScheduleIcon" width="100" contain />
            </div> -->

            <h1>Create a New Schedule</h1>
         </v-col>
      </v-row>

      <v-row class="py-5 align-center justify-center">
         <v-col cols="11" md="5" class="pa-4 d-flex align-center justify-center">
            <p class="mx-auto text-center font-weight-medium text-h6 text-sm-h5">
               Customize your own Visual Schedules to save, print or use in their digital format. Enjoy the
               ever-increasing library of beautiful images and easily upload your own for that personalized touch.
            </p>
         </v-col>
      </v-row>

      <!--Icon Buttons-->
      <!-- <v-row class="d-flex align-center justify-center">
         <v-col cols="12" class="d-flex align-center justify-center">
            <div
               class="ma-0 pa-6 d-flex flex-column align-center justify-center"
               v-for="icon in icons"
               :key="icon.title"
            >
               <div
                  class="pa-5 icon-background-active"
                  :class="[icon.title == active ? 'icon-background-active' : 'icon-background']"
                  @click="active = icon.title"
               >
                  <v-img v-if="icon.image" :src="assets.icons.libraryActive" max-height="40" max-width="40" />
                  <v-icon v-if="icon.icon" contain size="40" @click="iconClick(icon)" color="schedules">{{
                     icon.icon
                  }}</v-icon>
               </div>
               <div class="icon-text text-center font-weight-bold mt-4" v-html="icon.html"></div>
            </div>
         </v-col>
      </v-row> -->

      <!--Schedule Maker-->
      <v-row class="py-8 align-center justify-center">
         <v-col cols="12" class="pa-0 d-flex align-center justify-center">
            <schedule-image ref="scheduleImageRef"></schedule-image>
         </v-col>
      </v-row>
   </v-container>
</div>
</template>

<script>
import scheduleImage from "@/components/schedule/schedule-images.vue";
import { mapState } from "vuex";
export default {
   components: { scheduleImage },
   data() {
      return {
         active: "Add All Schedules",
      };
   },
   methods: {
      //icons view all schedules or upload a photo
      async iconClick(icon) {
         if (icon?.route) {
            this.$router.push(icon.route);
         }

         if (icon?.photoDialog) {
            this.$refs.scheduleImageRef.openViewImagesDialog();
         }
      },
   },
   computed: {
      ...mapState(["assets"]),
      icons() {
         return [
            {
               title: "Add All Schedules",
               icon: "mdi-calendar-plus",
               html: "View All <br/> Schedules",
               route: "/schedules",
            },
            {
               title: "View Uploaded Photos",
               icon: "mdi-image-multiple",
               html: "View Uploaded <br/> Photos",
               photoDialog: true,
            },
         ];
      },
   },
};
</script>
<style lang="scss">


.outer {
    background-color: #e0eced;
}


.icons-container-flex {
   column-gap: 15px;
   flex-wrap: wrap;
}

.icon-background {
   background-color: #e8e5e5;
   border-radius: 50%;
   flex: 0 0 auto;
}
.icon-background-active {
   background-color: white;
   border-radius: 50%;
   flex: 0 0 auto;
}
.icon-text {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>
